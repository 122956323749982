<template>
    <v-main>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container v-if="manufacturer">
                <v-row class="mb-9">
                    <Tabs
                        ref="tabEl"
                        @update="updateTab"
                        :tabs="tabs"
                        :allowUserChange="true"
                        :showCounter="false"
                    />
                </v-row>
                <v-row class="mt-16" v-show="currentTab === 'geral'"> <Geral /> </v-row>
                <v-row class="mt-16" v-show="currentTab === 'endereços'"> <Endereco /> </v-row>
                <v-row class="mt-16" v-show="currentTab === 'configurações'">
                    <Configuracoes />
                </v-row>
            </v-container>
        </div>
        <Loader v-if="1 == 2" mode="overlay" />
    </v-main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Tabs from '@/components/base/Tabs';
import Loader from '@/components/base/Loader';
import Endereco from './Endereco';
import Configuracoes from './Configuracoes';
import Geral from './Geral';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
export default {
    components: {
        Tabs,
        Loader,
        Endereco,
        Geral,
        Configuracoes,
    },
    data() {
        return {
            tabs: [
                {
                    name: this.$tc('defaults.tabs.general'),
                    path: 'geral',
                },
                {
                    name: this.$tc('defaults.tabs.addresses'),
                    path: 'endereços',
                },
                {
                    name: this.$tc('defaults.tabs.config'),
                    path: 'configurações',
                },
            ],
            currentTab: null,
            RequestStatusEnum,
        };
    },
    methods: {
        ...mapActions('cadastros', {
            fetchManufacturer: 'fetchManufacturer',
            updateManufacturerDetail: 'updateManufacturerDetail',
        }),
        ...mapMutations('cadastros', {
            setManufacturerDetail: 'setManufacturerDetail',
        }),
        doSearch() {
            this.fetchManufacturer({
                idProduct: this.selectedProduct.id,
                id: this.manufacturerId,
            });
        },
        updateTab(item) {
            if (item) {
                this.currentTab = item.path;
            }
        },
    },
    mounted() {
        this.currentTab = 'geral';
        if (!this.manufacturer) {
            this.doSearch();
        }
    },
    computed: {
        ...mapGetters('cadastros', {
            manufacturer: 'manufacturerDetail',
            manufacturerDetailRequestStatus: 'manufacturerDetailRequestStatus',
            updateManufacturerDetailRequestStatus: 'updateManufacturerDetailRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
        manufacturerId() {
            return this.$route.params.id;
        },
    },
    watch: {
        updateManufacturerDetailRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    this.$tc('cadastros.manufacturer.update.success')
                );
                this.$router.push(`/admin/cadastros/produtores`);
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('cadastros.manufacturer.update.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    destroyed() {
        this.setManufacturerDetail(null);
    },
};
</script>
