<template>
    <v-form class="form-custom-disabled">
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.addressType"
                    label="Tipo"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.zipCode"
                    label="CEP"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.address"
                    label="Endereço"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.number"
                    label="Número"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.complment"
                    label="Complemento"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.neighborhood"
                    label="Bairro"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.city"
                    label="Cidade"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.state"
                    label="Estado"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            manufacturer: 'cadastros/manufacturerDetail',
        }),
    },
};
</script>

<style></style>
