<template>
    <v-form class="form-custom-disabled">
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <label>Status</label>
                <v-radio-group v-model="manufacturer.status" row>
                    <v-radio
                        v-for="item in itemsStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <label>Assinatura Mandato</label>
                <v-radio-group v-model="manufacturer.signaturee" row>
                    <v-radio
                        v-for="item in items"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <label>Adesão ao Programa</label>
                <v-radio-group v-model="manufacturer.adherence" row>
                    <v-radio
                        v-for="item in items"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <label>Liberação Originador</label>
                <v-radio-group v-model="manufacturer.originatorLiberation" row>
                    <v-radio
                        v-for="item in items"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.fullRegistration"
                    label="Cadastro Completo"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.signatory"
                    label="Signatários"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
                <v-select
                    color="secondary"
                    :items="segments"
                    v-model="manufacturer.rating"
                    label="Rating"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
                <v-select
                    color="secondary"
                    :items="resales"
                    item-text="name"
                    item-value="id"
                    multiple
                    v-model="manufacturer.resale"
                    label="Revendas"
                ></v-select>
            </v-col>
        </v-row>
        <v-btn @click="saveData" color="secondary" class="mt-6">
            Salvar
        </v-btn>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';

export default {
    data: () => ({
        itemsStatus: [
            {
                label: 'Ativo',
                value: 1,
            },
            {
                label: 'Inativo',
                value: 0,
            },
        ],
        items: [
            {
                label: 'Sim',
                value: 1,
            },
            {
                label: 'Não',
                value: 0,
            },
        ],
    }),
    computed: {
        ...mapGetters({
            manufacturer: 'cadastros/manufacturerDetail',
            segments: 'cadastros/segmentsList',
            resales: 'cadastros/resaleList',
            selectedProduct: 'cadastros/selectedProduct',
            updateManufacturerConfigRequestStatus:
                'cadastros/updateManufacturerConfigRequestStatus',
        }),
    },
    watch: {
        updateManufacturerConfigRequestStatus(value) {
            if (value === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Dados atualizados com sucesso');
                return RequestStatusEnum.ERROR;
            }
        },
    },
    methods: {
        ...mapActions({
            updateManufacturerConfig: 'cadastros/updateManufacturerConfig',
        }),
        saveData() {
            const payload = {
                status: this.manufacturer.status,
                assinaturaMandato: this.manufacturer.signature,
                adesaoPrograma: this.manufacturer.adherence,
                liberacaoOriginador: this.manufacturer.originatorLiberation,
                segmento: this.manufacturer.rating,
                revendas: this.manufacturer.resale,
            };
            this.updateManufacturerConfig({
                id: this.manufacturer.id,
                idProduct: this.selectedProduct.id,
                payload,
            });
        },
    },
};
</script>

<style></style>
